// overwrite bootstrap theme variables to give us our own flavour of theme

$primary: #bd93f9;
$secondary: #05c2c4;
$dark: #282a36;
$light: #f8f8f2;
$success: #50fa7b;
$info: #a38d65;
$warning: #ff79c6;
$danger: #c23838;

$alternativebg: #1c1629;
$alternativetext: #dcc2f5;
$raisedbg: #44475a;
// Specify some more specific theme choices for certain classes and elements

html {
  min-height: 100vh !important;
}

body {
  background-color: $dark !important;
  height: 100vh !important;
  // height: 100% !important;
  min-width: 100% !important;
  font-family: Georgia !important;
  
  // position: relative !important;
  // font-size:xx-large !important;
  // font-style:oblique !important;
}

// Tried adjusting the PieChart size to no avail
// .VictoryContainer svg, g {
//   min-height: 20em !important;
//   min-width: 20em !important;
// }
aside {
  float: right;
}

.card {
  border-color: $primary !important;
}

.card-header {
  background-color: $dark !important;
}
.ThreeBlindMice {
  background-color: $dark !important;
  border-color: $primary !important;
}
.root {
  // min-height: 100% !important;
  // min-width: 100% !important;
  height: 100vh !important;
}
.app {
  // min-height: 100% !important;
  // min-height: 100% !important;
  height: 100vh !important;
  // min-width: 100% !important;
  // position: relative !important;
  // min-width: 100% !important;
}

#party {
  height: 85% !important;
  
  // height: 100 !important;
}



#root {
  height: 100% !important;
  width: 100% !important;
}

svg.svg-inline--fa {
  color: $secondary !important;
}

.nav-link {
  font-size: x-large !important;
  color: $light !important;
  text-align: left !important;
  margin-left: 22em;
}

small {
  font-size: large !important;
}

.rice {
  font-size: x-large !important;
}

#beans {
  color: $primary !important;
  text-decoration: underline !important;
  font-size: xx-large !important;

}



.show, .collapsing, .accordion, .accordion-item, .accordion-header {
  border-color: $primary !important;
  background-color: $dark !important;
  color: $light !important;
}

button.me-2.btn.btn-outline-info, button.btn.btn-outline-danger {
  border-width: 0.3em !important;
  
}

// button.me-2.btn.btn-outline-info {
  
// }

// button.btn.btn-outline-danger {

// }

div.mb-3 {
  border-color: $primary !important;
}

h1 {
  font-size: xx-large !important;
  color: $secondary !important;
}

h2 {
  font-size: x-large !important;
  color: $light !important;
}

p {
  font-size: x-large !important;
}

.navbar-brand {
  color: $secondary !important;
  font-size: xx-large !important;
}

// .VictoryContainer > svg:nth-child(1), .VictoryContainer, .VictoryContainer > svg:nth-child(1) > g:nth-child(1) > path:nth-child(1) {
//   height: 600px !important;
// }

// g {

//   width: 250px !important;
//   height: 650px !important; 

// }

a {
  color: $secondary !important;
  // font-size: x-large !important;
  
}

a:hover {
  text-decoration: underline !important;
  animation: alternate !important;
}

.iAmTheNavBar {
  border-bottom: 0.25em !important;
  border-width: 0.25em !important;
  border-color: $dark !important;
  border-bottom-color: $raisedbg !important;
  border-style:solid !important;
}

.dropdown-menu.show, a.dropdown-item {
background-color: $dark !important;
color: $secondary !important;
}

button {
  background-color: $raisedbg !important;
  color: $light !important;
  font-size: larger !important;
}


span.navbar-toggler-icon {
  background-color: #03dac500 !important;
  color: $dark !important;
}

.navbar-toggler-icon {
  background-color: $dark !important;
}

// .navbar-toggler.collapsed {
//   background-color: $dark !important;
// }

.button.navbar-toggler {
  color: $success !important;
}

.form-control {
  background-color: $dark !important;
  color: $light !important;
  min-height: 3em !important;

  
}

.card-body {
  background-color: $raisedbg;
}

.list-group-item {
  background-color: $dark !important;
  color: $light !important;
}

#rumpledoop {
  background-color: $dark !important;
  color: $light !important;
  //position:absolute !important;
  // left:0 !important;
  //bottom:0 !important;
  // right:0 !important;
  
}

#rumpleboop {
  background-color: $dark !important;
  color: $light !important;
  font-size: larger;
  border-bottom: 0.25em !important;
  border-width: 0.25em !important;
  border-color: $dark !important;
  border-top-color: $raisedbg !important;
  border-style:solid !important;
  // bottom: 0 !important;
  margin-top:4em;
  // // // left: 0 !important;
  // // // right: 0 !important;
   position:sticky !important;
  // position: fixed;
  // bottom: 0;
  // margin-top: -180px; /* negative value of footer height */
  // height: 180px;
  // clear:both;
  
}

table, tr, th, thead, tbody, td {
  color: $light !important;
  border-color: $raisedbg !important;
}

span {
  background-color: $dark !important;
  color: $light !important;
  
}

.accordion-body {
  font-size: x-large !important;
}

#pieface {
  max-width: 40em !important;
  max-height: 40em !important;
}

@media only screen and (max-width: 1200px) {
  .nav-link {
    
    margin-left: 6em !important;
  }
  .rice {
    font-size: larger !important;
  }
  #beans {
    font-size: x-large !important;
  }
  .accordion-body {
    font-size: larger !important;
  }
}
@media only screen and (max-width: 600px) {
  .navbar-brand {
    font-size: larger !important;
  }
  .rice {
    font-size: larger !important;
  }
  #beans {
    font-size: large !important;
  }
  .accordion-body {
    font-size: large !important;
  }
  #rumpleboop {
    font-size: large !important;
  }
  h1 {
    font-size: xx-large !important;
  }
  h2 {
    font-size: x-large !important;
  }
  p {
    font-size: larger !important;
  }
  div.bg-dark:nth-child(2) {
    padding:2px !important;
  }
  .nav-link {
    margin-left: 25px !important;
    text-align: left !important;
  }
}

// The following section depicts what our alternative theme should look like
// where we switch theme on the /settings page

.light {
  background-color: $alternativebg !important;
  color: $alternativetext !important;

  // :not(.chartsmate.container) {
  //   background-color: none !important;
  //   color: $light !important;
  // }

  // :not(.VictoryContainer) {
  //   background-color: none !important;
  //   color: $light !important;
  // }

  div {
    background-color: $alternativebg !important;
    color: $alternativetext !important;
  }
  form {
    background-color: $alternativebg !important;
    color: $alternativetext !important;
  }
  h1 {
    background-color: $alternativebg !important;
    color: $alternativetext !important;
  }
  Card {
    background-color: $alternativebg !important;
    color: $alternativetext !important;
  }
  LeNavBar {
    background-color: $alternativebg !important;
    color: $alternativetext !important;
  }
  nav {
    background-color: $alternativebg !important;
    color: $alternativetext !important;
  }
  Card.Text {
    background-color: $alternativebg !important;
    color: $alternativetext !important;
  }
  Form.Control {
    background-color: $alternativebg !important;
    color: $alternativetext !important;
  }
  div.div {
    background-color: $alternativebg !important;
    color: $alternativetext !important;
  }
  div.div.div {
    background-color: $alternativebg !important;
    color: $alternativetext !important;
  }
  .text-white {
    background-color: $alternativebg !important;
    color: $alternativetext !important;
  }
  .navbar-dark {
    background-color: $alternativebg !important;
    color: $alternativetext !important;
  }
  .navbar-dark .navbar-dark {
    background-color: $alternativebg !important;
    color: $alternativetext !important;
  }
  .bg-dark {
    background-color: $alternativebg !important;
    color: $alternativetext !important;
  }
  .card-header {
    background-color: #9d03da41 !important;
  }
  .ThreeBlindMice {
    background-color: #3d0155 !important;
    border-color: #63028a71 !important;
  }

  .list-group-item {
    background-color: rgba(48, 0, 87, 0.185) !important;
    color: #f6f2f8 !important;
  }
  div.card-title.h5 {
    background-color: #9d03da00 !important;
  }
  .text-center.mb-3.card.bg-dark.text-light.border-secondary {
    border-color: rgba(126, 2, 174, 0.26) !important;
  }
  .border-secondary {
    border-color: $primary !important;
  }
  Button {
    background-color: #71148d !important;
  }
  span.navbar-toggler-icon {
    background-color: #4e118800 !important;
    color: $dark !important;
  }
  #rumpleboop {
    background-color: #00ffe500 !important;
  }
  #rumpledoop {
    background-color: #00ffe500 !important;
  }
  .text-center.my-auto.copyright {
    background-color: #00ffe500 !important;
  }
  span {
    background-color: #00ffe500 !important;
  }
  .dropdown-menu.show, a.dropdown-item {
    background-color: $alternativebg !important;
    color: $alternativetext !important;
    }
}

.dark {
  background-color: $dark;
  color: $light;
}

// import bootstrap styles so we can override them
@import 'node_modules/bootstrap/scss/bootstrap.scss';
